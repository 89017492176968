<template>
	<div>
		<Modal v-model="show" title="批量添加" width="800" @on-cancel="close()">
			<Form label-position="top" label-colon>
				<Row :gutter="16">
					<Col flex="2">
					<FormItem label="厂家名称">
						<Select v-model="data.brand_id" @on-change="selectBrand">
							<Option :value="0">请选择</Option>
							<Option v-for="(item,index) in brandItem" :value="item.value" :key="index">{{ item.label }}
							</Option>
						</Select>
					</FormItem>
					<FormItem label="设备类型">
						<Select v-model="data.cate_id" @on-change="selectCate" :disabled="data.brand_id ? false : true">
							<Option :value="0">请选择</Option>
							<Option v-for="(item,index) in cateItem" :value="item.value" :key="index">{{ item.label }}
							</Option>
						</Select>
					</FormItem>
					<FormItem label="设备型号">
						<Select v-model="data.type_id" :disabled="data.cate_id ? false : true">
							<Option :value="0">请选择</Option>
							<Option v-for="(item,index) in typeItem" :value="item.value" :key="index">{{ item.label }}
							</Option>
						</Select>
					</FormItem>
					<FormItem v-if="data.brand_id == 12" label="固件版本">
						<Input v-model="data.version" placeholder="请输入固件版本"></Input>
					</FormItem>
					</Col>
					<Col flex="auto">
					<FormItem label="设备id(一行一个)">
						<Input v-model="data.device_ids" type="textarea" :rows="24" placeholder="" />
					</FormItem>
					</Col>
					<Col flex="1" v-if="data.brand_id == 3">
					<FormItem label="设备验证码">
						<Input v-model="data.validate_code" type="textarea" :rows="24" placeholder="" />
					</FormItem>
					</Col>
					<!-- <Col flex="1" v-if="data.brand_id == 12">
					<FormItem label="version">
						<Input v-model="data.version" placeholder="请输入固件版本"></Input>
					</FormItem>
					</Col> -->
				</Row>
			</Form>
			<template slot="footer">
				<Button @click="close()">取消</Button>
				<Button type="primary" @click="saveData()" :loading="btn_loading">确定</Button>
			</template>
		</Modal>

		<Modal v-model="modal.show" title="未添加成功说明">
			<Table :columns="modal.columns" :data="modal.data" max-height="500"></Table>
			<template slot="footer">
				<Button @click="modal.show = false">关闭</Button>
			</template>
		</Modal>
	</div>
</template>

<script>
	var _this, cateItem, typeItem, FormData = {
		brand_id: 0, // 厂家id
		type_id: 0, // 型号id
		cate_id: 0, // 类型id
		device_ids: '', // 设备id
		validate_code: '', // 设备验证码（监控独有）
		mac: '', // mac (睡眠监测仪独有)
	};
	export default {
		name: 'BatchAdd',
		data() {
			return {
				show: false,
				btn_loading: false,
				data: JSON.parse(JSON.stringify(FormData)),
				brandItem: [], // 设备厂家数组
				cateItem: [], // 设备类型数组
				typeItem: [], // 设备型号数组
				modal: {
					show: false,
					columns: [{
							title: '设备id',
							key: 'device_imei'
						},
						{
							title: '失败原因',
							key: 'error'
						},
					],
					data: []
				}
			}
		},
		methods: {
			// 打开弹窗
			open(brand, cate, type) {
				_this = this;
				this.show = true;
				this.data = JSON.parse(JSON.stringify(FormData));
				this.brandItem = brand;
				cateItem = cate;
				typeItem = type;
			},
			// 选择厂家
			selectBrand(brandId) {
				this.cateItem = [];
				this.data.cate_id = 0;
				if (brandId) {
					var data = {};
					for (let key in this.brandItem) {
						if (this.brandItem[key].value == brandId) {
							data = this.brandItem[key];
						}
					}
					for (let index in cateItem) {
						console.log(data.device_type.indexOf(String(cateItem[index].value - 1)))
						if (data.device_type.indexOf(String(cateItem[index].value - 1)) != -1) {
							this.cateItem.push(cateItem[index]);
						}
					}
				}
			},
			// 选择类型
			selectCate(cateId) {
				this.typeItem = [];
				this.data.type_id = 0;
				if (cateId) {
					for (let index in typeItem) {
						if (typeItem[index].cate_id == this.data.cate_id && typeItem[index].brand_id == this.data
							.brand_id) {
							this.typeItem.push(typeItem[index]);
						}
					}
				}
			},
			// 保存配置
			saveData() {
				if (this.data.brand_id == 0) {
					return this.alertErr('请选择设备厂家');
				}
				if (this.data.cate_id == 0) {
					return this.alertErr('请选择设备类型');
				}
				if (this.data.type_id == 0) {
					return this.alertErr('请选择设备型号');
				}
				if (this.data.device_ids == '') {
					return this.alertErr('请至少输入一个设备id');
				}
				if (this.data.brand_id == 12 && !this.data.version) {
					return this.alertErr('请输入固件版本')
				}
				var data = JSON.parse(JSON.stringify(this.data));
				data.device_ids = data.device_ids.replace(/\n/g, ",");
				data.validate_code = data.validate_code.replace(/\n/g, ",");
				data.mac = data.mac.replace(/\n/g, ",");
				this.btn_loading = true;
				this.requestApi('/adm/batch_add_device', data).then((res) => {
					this.btn_loading = false;
					if (res.status == 1) {
						_this.alertSucc(res.msg);
						if (res.msg !== '添加成功') {
							_this.modal.show = true;
							_this.modal.data = res.err_data;
						} else {
							_this.close();
						}
						_this.$emit('addSucc');
					} else {
						_this.alertErr(res.msg);
					}
				})
				console.log(this.data);
			},
			// 取消 关闭弹窗
			close() {
				this.show = false;
			}
		}
	}
</script>

<style scoped>

</style>