<template>
	<div>
		<div class="ser-form">
			<Form ref="form" :model="serData">
				<div class="ser-form-item">
					<div class="form-item-label">搜索</div>
					<div class="form-item-content">
						<Input v-model="serData.device_id" placeholder="设备ID" />
						<Button type="primary" icon="md-search" @click="searchInit">搜索</Button>
					</div>
				</div>
				<div class="ser-form-item">
					<div class="form-item-label">筛选</div>
					<div class="form-item-content">
						<Select v-model="serData.brand_id" style="width: 190px;" placeholder="请选择厂家"
							@on-change="selectBrand">
							<Option :value="0">请选择厂家</Option>
							<Option v-for="(item,index) in brandItem" :value="item.value" :key="index">{{ item.label }}
							</Option>
						</Select>
						<Select v-model="serData.cate_id" style="width: 190px;margin-right: 8px;" placeholder="请选择分类"
							:disabled="!Boolean(serData.brand_id)" @on-change="selectCate">
							<Option :value="0">请选择分类</Option>
							<Option v-for="(item,index) in cateItem" :value="item.value" :key="index">{{ item.label }}
							</Option>
						</Select>
						<Select v-model="serData.type_id" style="width: 190px;margin-right: 8px;" placeholder="请选择型号"
							:disabled="!Boolean(formData.cate_id)">
							<Option :value="0">请选择型号</Option>
							<Option v-for="(item,index) in typeItem" :value="item.value" :key="index">{{item.label}}
							</Option>
						</Select>

					</div>
				</div>
				<div class="ser-form-item">
					<div class="form-item-label">操作</div>
					<div class="form-item-content">
						<Button type="primary" icon="md-add" @click="openModal"
							style="margin-right: 10px;">新增设备</Button>
						<Button type="primary" icon="md-add" @click="batch_add">批量新增</Button>
					</div>
				</div>
			</Form>
		</div>
		<div class="table-list">
			<Table :loading="loading" :columns="columns" :data="data">
				<template slot-scope="{row}" slot="thumb">
					<div>
						<img v-if="row.thumb" style="width: 60px;height: 60px;" :src="serveUrl+row.thumb" alt="" />
						<span v-else>--</span>
					</div>
				</template>
				<template #cloudInfo="{row}">
					<template v-if="parseInt(row.cloud_id) === 0">
						<div>未绑定云端账号</div>
					</template>
					<template v-else>
						<div>ID：{{ row.cloud_id }}</div>
						<div>名称：{{ row.cloud_name }}</div>
					</template>

				</template>
				<template slot-scope="row" slot="action">
					<Button @click="edit(row.index)" style="margin-right: 8px;">编辑</Button>
					<Button @click="del(row.index)">删除</Button>
				</template>
			</Table>
		</div>
		<div class="ser-page">
			<Page class-name="page-r" :total="total" :page-size="serData.pageSize" :page-size-opts="[15,30,50]"
				@on-change="changePage" @on-page-size-change="changePageSize" show-total show-sizer></Page>
		</div>
		<Modal title="新增设备" v-model="model">
			<Form ref="formValidate" :model="formData" :rules="ruleValidate" :label-width="100">
				<FormItem label="厂家" prop="brand_id">
					<Select v-model="formData.brand_id" style="width: 200px;" placeholder="请选择厂家"
						@on-change="selectBrand">
						<Option v-for="(item,index) in brandItem" :value="item.value" :key="index">{{ item.label }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="分类" prop="cate_id">
					<Select v-model="formData.cate_id" style="width: 200px;" placeholder="请选择分类"
						:disabled="!Boolean(formData.brand_id)" @on-change="selectCate">
						<Option v-for="(item,index) in cateItem" :value="item.value" :key="index">{{ item.label }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="型号" prop="type_id">
					<Select v-model="formData.type_id" style="width: 200px;" placeholder="请选择型号"
						:disabled="!Boolean(formData.cate_id)">
						<Option v-for="(item,index) in typeItem" :value="item.value" :key="index">{{ item.label }}
						</Option>
					</Select>
				</FormItem>
				<!--        <FormItem label="图标">
                  <img v-if="formData.thumb" style="width: 75px;height: 75px;margin-right: 8px;" :src="ApiUrl+formData.thumb" alt="" />
                  <Uploads style="display: inline-block;" :action="ApiUrl+'/adm/upload_img'" :data="{dir:'device_thumb'}" @upload_succ="upload_succ"></Uploads>
                </FormItem> -->
				<FormItem label="设备id" prop="device_id">
					<Input v-model="formData.device_id" placeholder="请输入设备号"></Input>
				</FormItem>
				<FormItem label="设备验证码" v-if="formData.brand_id == 3">
					<Input v-model="formData.validate_code" placeholder="请输入，验证码在设备底部"></Input>
				</FormItem>
				<FormItem label="固件版本" required v-if="formData.brand_id == 12">
					<Input v-model="formData.version" placeholder="请输入固件版本"></Input>
				</FormItem>
				<FormItem label="状态">
					<RadioGroup v-model="formData.status">
						<Radio :label="1">显示</Radio>
						<Radio :label="0">不显示</Radio>
					</RadioGroup>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="primary" @click="save">保存</Button>
				<Button @click="model=false">取消</Button>
			</div>
		</Modal>
		<batch-add ref="BatchAdd" @addSucc="init()"></batch-add>
	</div>
</template>

<script>
	import BatchAdd from '../../../components/device/batch_add';

	var cateItem = [],
		typeItem = [];
	export default {
		name: "DeviceList",
		components: {
			BatchAdd
		},
		data() {
			return {
				apiUrl: this.ApiUrl,
				loading: false,
				model: false,
				batch_model: false,
				device_arr: {}, //添加设备数据
				device_data: {},
				serveUrl: this.serveUrl,
				serData: {
					device_id: '',
					cate_id: 0,
					type_id: 0,
					brand_id: 0,
					page: 1,
					pageSize: 15
				},
				formData: {
					id: null,
					device_id: '',
					cate_id: null,
					type_id: null,
					brand_id: null,
					thumb: '',
					validate_code: '', // 设备验证码 （监控）
					// mac: '', // mac (睡眠检测仪)
					status: 1,
				},
				ruleValidate: {
					cate_id: [{
						required: true,
						type: 'number',
						message: '请选择分类',
						trigger: 'change'
					}],
					type_id: [{
						required: true,
						type: 'number',
						message: '请选择型号',
						trigger: 'change'
					}],
					brand_id: [{
						required: true,
						type: 'number',
						message: '请选择品牌',
						trigger: 'change'
					}],
					device_id: [{
						required: true,
						type: 'string',
						message: '设备Id不能为空',
						trigger: 'blur'
					}],
				},
				cateItem: [],
				typeItem: [],
				brandItem: [],
				columns: [{
						title: 'ID',
						key: 'id',
						width: 60,
						align: 'center'
					},
					{
						title: '分类',
						key: 'cate_name',
						minWidth: 70
					},
					{
						title: '型号',
						key: 'type_name',
						minWidth: 70
					},
					{
						title: '厂家',
						key: 'brand_name',
						minWidth: 70
					},
					{
						title: '绑定云端',
						slot: 'cloudInfo',
						width: 140
					},
					{
						title: '设备id',
						key: 'device_id',
						minWidth: 100
					},
					{
						title: '状态',
						key: 'status',
						minWidth: 80,
						render: (h, params) => {
							return h('div', [
								h('Tag', {
									props: {
										color: params.row.status ? 'success' : 'error'
									}
								}, params.row.status ? '显示' : '隐藏')
							])
						}
					},
					{
						title: '添加时间',
						key: 'create_time',
						minWidth: 160,
						render: (h, params) => {
							return h('div', params.row.create_time ? params.row.create_time : '--')
						}
					},
					{
						title: '排序',
						key: 'sort',
						width: 70
					},
					{
						title: '操作',
						slot: 'action',
						minWidth: 150
					}
				],
				data: [],
				total: 0,
				device_result: {
					status: null
				},
			}
		},
		created() {
			this.init();
			this.serInit();
		},
		methods: {
			serInit() {
				var _this = this;
				this.requestApi('/adm/get_search').then(function(res) {
					_this.brandItem = res.brandItem;
					cateItem = res.cateItem;
					typeItem = res.typeItem;
				})
			},
			init() {
				var _this = this;
				this.loading = true;
				this.requestApi('/adm/get_device_list', {
					data: this.serData
				}).then(function(res) {
					if (res.status) {
						_this.loading = false;
						_this.total = res.data.total;
						_this.data = res.data.data;
					}
				})
			},
			upload_succ(res) {
				this.formData.thumb = res.pic_url;
			},
			save() {
				var _this = this;
				this.$refs.formValidate.validate((valid) => {
					if (valid) {
						if (_this.formData.brand_id == 12 && !_this.formData.version) {
							return _this.alertErr('请输入固件版本')
						}
						_this.requestApi('/adm/save_device', {
							data: _this.formData
						}).then(function(res) {
							if (res.status) {
								_this.model = false;
								_this.init();
							} else {
								_this.alertErr(res.msg)
							}
						})
					}
				})
			},
			del(index) {
				var _this = this;
				this.$Modal.confirm({
					title: '提示',
					content: '确认要删除该数据吗？',
					onOk() {
						_this.requestApi('/adm/del_device', {
							id: _this.data[index].id
						}).then(function(res) {
							if (res.status) _this.init();
							else _this.alertErr(res.msg);
						})
					}
				})
			},
			openModal() {
				this.$refs.formValidate.resetFields();
				this.formData.id = null;
				this.formData.device_id = null;
				this.formData.cate_id = null;
				this.formData.type_id = null;
				this.formData.brand_id = null;
				this.formData.thumb = '';
				this.formData.status = 1;
				this.formData.version = null
				this.model = true;
			},
			edit(index) {
				var obj = JSON.parse(JSON.stringify(this.data[index]));
				this.formData.id = obj.id;
				this.formData.device_id = obj.device_id;
				// 选择厂家
				this.formData.brand_id = obj.brand_id;
				this.selectBrand(obj.brand_id);
				// 选择分类
				this.formData.cate_id = obj.cate_id;
				this.selectCate(obj.cate_id);
				// 型号
				this.formData.type_id = obj.type_id;
				this.formData.thumb = obj.thumb;
				this.formData.status = obj.status;
				this.formData.version = obj.version
				this.model = true;
			},
			searchInit() {
				this.serData.page = 1;
				this.init();
			},
			changePage(page) {
				this.serData.page = page;
				this.init();
			},
			changePageSize(size) {
				this.serData.page = 1;
				this.serData.pageSize = size;
				this.init();
			},
			batch_add() {
				this.$refs.BatchAdd.open(this.brandItem, cateItem, typeItem);
			},
			uploadSuccess(res) {
				this.$set(this.device_arr, 'file_name', res.file_name)
				this.$set(this.device_arr, 'file_url', res.file_url)
				this.device_data.file_name = res.file_name
				this.device_data.file_url = res.file_url
			},
			delFile() {
				this.device_arr = []
			},
			// 选择厂家
			selectBrand(brandId) {
				this.cateItem = [];
				this.formData.cate_id = 0;
				if (brandId) {
					var data = {};
					for (let key in this.brandItem) {
						if (this.brandItem[key].value == brandId) {
							data = this.brandItem[key];
						}
					}
					for (let index in cateItem) {
						if (data.device_type.indexOf(String(cateItem[index].value - 1)) != -1) {
							this.cateItem.push(cateItem[index]);
						}
					}
				}
			},
			// 选择类型
			selectCate(cateId) {
				this.typeItem = [];
				this.formData.type_id = 0;
				if (cateId) {
					for (let index in typeItem) {
						if (typeItem[index].cate_id == this.formData.cate_id && typeItem[index].brand_id == this.formData
							.brand_id) {
							this.typeItem.push(typeItem[index]);
						}
					}
				}
			},
		}
	}
</script>

<style scoped>
	.btn-del {
		float: right;
		display: none;
	}

	.upload-item:hover .btn-del {
		display: inline-block;
	}
</style>